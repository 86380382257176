var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "drawer-layout",
    {
      attrs: {
        title: _vm.setTitle,
        show: _vm.open,
        isForm: _vm.isForm,
        permission: "system:coefficient:edit",
      },
      on: {
        show: _vm.show,
        closed: _vm.closed,
        save: _vm.save,
        edit: _vm.edit,
      },
    },
    [
      _c(
        "div",
        { attrs: { slot: "" }, slot: "default" },
        [
          _c("g-title", [_vm._v("基本信息")]),
          _c(
            "info-item",
            { attrs: { "label-width": _vm.labelWidth, label: "大类名称" } },
            [_vm._v(_vm._s(_vm.curRow.bigType))]
          ),
          _c(
            "info-item",
            { attrs: { "label-width": _vm.labelWidth, label: "小类名称" } },
            [_vm._v(_vm._s(_vm.curRow.smallType))]
          ),
          _c(
            "info-item",
            { attrs: { "label-width": _vm.labelWidth, label: "序号" } },
            [_vm._v(_vm._s(_vm.curRow.sort))]
          ),
          _c("g-title", [_vm._v("正文")]),
          _c(
            "info-item",
            { attrs: { "label-width": _vm.labelWidth, label: "选项类型" } },
            [
              _vm._v(
                _vm._s(
                  _vm.curRow.ruleType == "STATISTICS"
                    ? "统计"
                    : _vm.curRow.ruleType == "SELECT"
                    ? "下拉选项"
                    : ""
                )
              ),
            ]
          ),
          _c(
            "info-item",
            { attrs: { "label-width": _vm.labelWidth, label: "取值目标" } },
            [_vm._v(_vm._s(_vm.getCalcTarget(_vm.curRow.calcTargetId)))]
          ),
          _vm.curRow.ruleType != "SELECT"
            ? _c(
                "info-item",
                { attrs: { "label-width": _vm.labelWidth, label: "取值范围" } },
                [
                  _vm._v(
                    _vm._s(
                      _vm.curRow.calcRange
                        ? _vm.getCalcRange(_vm.curRow.calcRange)
                        : _vm.curRow.calcRange
                    )
                  ),
                ]
              )
            : _vm._e(),
          _vm.curRow.ruleType != "SELECT"
            ? _c(
                "info-item",
                { attrs: { "label-width": _vm.labelWidth, label: "计算公式" } },
                [_vm._v(_vm._s(_vm.curRow.formula))]
              )
            : _vm._e(),
          _vm.curRow.ruleType != "SELECT"
            ? _c(
                "info-item",
                { attrs: { "label-width": _vm.labelWidth, label: "计算逻辑" } },
                [
                  _vm._v(
                    _vm._s(
                      _vm.curRow.calcLogic == "FIXED"
                        ? "固定值"
                        : _vm.curRow.calcLogic == "AVERAGE_VALUABLE"
                        ? "平均值(除以单独取值话数)"
                        : _vm.curRow.calcLogic == "AVERAGE_FIXED"
                        ? "平均值(除以固定话数)"
                        : _vm.curRow.calcLogic == "SUM"
                        ? "总和"
                        : ""
                    )
                  ),
                ]
              )
            : _vm._e(),
          _vm.curRow.calcLogic == "AVERAGE_FIXED"
            ? _c("info-item", { attrs: { label: "固定话数" } }, [
                _c("span", [_vm._v(_vm._s(_vm.curRow.calcCount))]),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        {
          attrs: { slot: "form" },
          on: { click: _vm.closeSelect },
          slot: "form",
        },
        [
          _c("g-title", [_vm._v("基本信息")]),
          _c(
            "a-form-model",
            _vm._b(
              {
                ref: "dataForm",
                attrs: {
                  layout: "horizontal",
                  model: _vm.formData,
                  rules: _vm.rules,
                },
              },
              "a-form-model",
              _vm.layout,
              false
            ),
            [
              _c(
                "a-form-model-item",
                { attrs: { label: "大类名称", prop: "serialName" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入大类名称" },
                    model: {
                      value: _vm.formData.bigType,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "bigType", $$v)
                      },
                      expression: "formData.bigType",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "小类名称", prop: "englishName" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入小类名称" },
                    model: {
                      value: _vm.formData.smallType,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "smallType", $$v)
                      },
                      expression: "formData.smallType",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "序号", prop: "sequenceNo" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入序号" },
                    model: {
                      value: _vm.formData.sort,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "sort", $$v)
                      },
                      expression: "formData.sort",
                    },
                  }),
                ],
                1
              ),
              _c("g-title", [_vm._v("正文")]),
              _c(
                "a-form-model-item",
                { attrs: { label: "选项类型" } },
                [
                  _c(
                    "a-select",
                    {
                      attrs: { placeholder: "请选择选项类型" },
                      model: {
                        value: _vm.formData.ruleType,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "ruleType", $$v)
                        },
                        expression: "formData.ruleType",
                      },
                    },
                    _vm._l(
                      [
                        { title: "统计", value: "STATISTICS" },
                        { title: "下拉选项", value: "SELECT" },
                      ],
                      function (item) {
                        return _c(
                          "a-select-option",
                          { key: item.value, attrs: { value: item.value } },
                          [_vm._v(_vm._s(item.title))]
                        )
                      }
                    ),
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "取值目标", prop: "styleName" } },
                [
                  _c(
                    "a-tree-select",
                    {
                      ref: "selectTree",
                      attrs: { placeholder: "请选择取值目标" },
                      on: { change: _vm.treeSelectChange },
                      model: {
                        value: _vm.formData.calcTargetId,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "calcTargetId", $$v)
                        },
                        expression: "formData.calcTargetId",
                      },
                    },
                    _vm._l(_vm.treeData, function (item) {
                      return _c(
                        "a-tree-select-node",
                        {
                          key: item.key,
                          attrs: { value: item.value, title: item.title },
                        },
                        [
                          _vm._l(item.children, function (data) {
                            return [
                              _c(
                                "a-tree-select-node",
                                {
                                  key: data.key,
                                  attrs: {
                                    value: data.value,
                                    title: data.title,
                                  },
                                },
                                _vm._l(data.children, function (i) {
                                  return _c("a-tree-select-node", {
                                    key: i.id,
                                    attrs: { value: i.id, title: i.title },
                                  })
                                }),
                                1
                              ),
                            ]
                          }),
                        ],
                        2
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm.formData.ruleType != "SELECT"
                ? _c(
                    "a-form-model-item",
                    { attrs: { label: "取值范围" } },
                    [
                      _c("edit-range-select", {
                        attrs: {
                          formData: _vm.formData.calcRange,
                          rangeKey: "type",
                          valueKey: "value",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.formData.ruleType != "SELECT"
                ? _c(
                    "a-form-model-item",
                    { attrs: { label: "计算公式" } },
                    [
                      _c("a-input", {
                        attrs: { placeholder: "请输入计算公式" },
                        model: {
                          value: _vm.formData.formula,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "formula", $$v)
                          },
                          expression: "formData.formula",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.formData.ruleType != "SELECT"
                ? _c(
                    "a-form-model-item",
                    { attrs: { label: "计算逻辑" } },
                    [
                      _c(
                        "a-select",
                        {
                          attrs: { placeholder: "请选择计算逻辑" },
                          model: {
                            value: _vm.formData.calcLogic,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "calcLogic", $$v)
                            },
                            expression: "formData.calcLogic",
                          },
                        },
                        _vm._l(
                          [
                            {
                              value: "AVERAGE_VALUABLE",
                              label: "平均值(除以单独取值话数)",
                            },
                            {
                              value: "AVERAGE_FIXED",
                              label: "平均值(除以固定话数)",
                            },
                            { value: "FIXED", label: "固定值" },
                            { value: "SUM", label: "总和" },
                          ],
                          function (item) {
                            return _c(
                              "a-select-option",
                              { key: item.value, attrs: { value: item.value } },
                              [_vm._v(_vm._s(item.label))]
                            )
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.formData.calcLogic == "AVERAGE_FIXED"
                ? _c(
                    "a-form-model-item",
                    { attrs: { label: "固定话数" } },
                    [
                      _c("a-input-number", {
                        attrs: { placeholder: "请输入固定话数" },
                        model: {
                          value: _vm.formData.calcCount,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "calcCount", $$v)
                          },
                          expression: "formData.calcCount",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }