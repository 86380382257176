<template>
  <drawer-layout
    :title="setTitle"
    :show="open"
    :isForm="isForm"
    permission="system:coefficient:edit"
    @show="show"
    @closed="closed"
    @save="save"
    @edit="edit"
  >
    <div slot>
      <g-title>基本信息</g-title>
      <info-item :label-width="labelWidth" label="大类名称">{{ curRow.bigType }}</info-item>
      <info-item :label-width="labelWidth" label="小类名称">{{ curRow.smallType }}</info-item>
      <info-item :label-width="labelWidth" label="序号">{{ curRow.sort }}</info-item>
      <!--       <info-item :label-width="labelWidth" label="所属系数">{{
        curRow.own == 'DIFFICULT' ? '作品难度系数' : curRow.own == 'FACTOR' ? '单拎系数' : ''
      }}</info-item> -->
      <!--       <info-item :label-width="labelWidth" label="适用部门">
        <a-tag v-for="one in curRow.departments" :key="one.departId">{{ one.departName }}</a-tag>
      </info-item>
      <info-item :label-width="labelWidth" label="适用职位">
        <a-tag v-for="one in curRow.positions" :key="one.positionId">{{ one.positionName }}</a-tag>
      </info-item> -->

      <g-title>正文</g-title>
      <info-item :label-width="labelWidth" label="选项类型">{{
        curRow.ruleType == 'STATISTICS' ? '统计' : curRow.ruleType == 'SELECT' ? '下拉选项' : ''
      }}</info-item>
      <info-item :label-width="labelWidth" label="取值目标">{{ getCalcTarget(curRow.calcTargetId) }}</info-item>
      <info-item v-if="curRow.ruleType != 'SELECT'" :label-width="labelWidth" label="取值范围">{{
        curRow.calcRange ? getCalcRange(curRow.calcRange) : curRow.calcRange
      }}</info-item>
      <info-item v-if="curRow.ruleType != 'SELECT'" :label-width="labelWidth" label="计算公式">{{
        curRow.formula
      }}</info-item>
      <info-item v-if="curRow.ruleType != 'SELECT'" :label-width="labelWidth" label="计算逻辑">{{
        curRow.calcLogic == 'FIXED'
          ? '固定值'
          : curRow.calcLogic == 'AVERAGE_VALUABLE'
          ? '平均值(除以单独取值话数)'
          : curRow.calcLogic == 'AVERAGE_FIXED'
          ? '平均值(除以固定话数)'
          : curRow.calcLogic == 'SUM'
          ? '总和'
          : ''
      }}</info-item>
      <info-item v-if="curRow.calcLogic == 'AVERAGE_FIXED'" label="固定话数">
        <span>{{ curRow.calcCount }}</span>
      </info-item>
    </div>

    <div slot="form" @click="closeSelect">
      <g-title>基本信息</g-title>
      <a-form-model ref="dataForm" layout="horizontal" :model="formData" :rules="rules" v-bind="layout">
        <a-form-model-item label="大类名称" prop="serialName">
          <a-input v-model="formData.bigType" :placeholder="'请输入大类名称'" />
        </a-form-model-item>
        <a-form-model-item label="小类名称" prop="englishName">
          <a-input v-model="formData.smallType" :placeholder="'请输入小类名称'" />
        </a-form-model-item>
        <a-form-model-item label="序号" prop="sequenceNo">
          <a-input v-model="formData.sort" placeholder="请输入序号"></a-input>
        </a-form-model-item>

        <!--         <a-form-model-item label="所属系数" prop="displayName">
          <a-select v-model="formData.own" placeholder="请选择所属系数">
            <a-select-option
              v-for="item in [
                { value: 'DIFFICULT', label: '作品难度系数' },
                { value: 'FACTOR', label: '单拎系数' }
              ]"
              :key="item.value"
              :value="item.value"
              >{{ item.label }}</a-select-option
            >
          </a-select>
        </a-form-model-item> -->

        <!--         <a-form-model-item label="适用部门" prop="selectedDepart">
          <a-tree-select
            v-model="selectedDeparts"
            style="width: 100%"
            tree-checkable
            :tree-data="departList"
            search-placeholder="选择部门"
            placeholder="请选择适用部门"
            @change="changeDepartList"
          />
        </a-form-model-item>
        <a-form-model-item label="适用职位" prop="selectedPositions">
          <a-tree-select
            v-model="selectedPositions"
            style="width: 100%"
            tree-checkable
            :tree-data="getTreePositionList"
            search-placeholder="选择职位"
            placeholder="请选择适用职位"
            @change="changePositionList"
          />
        </a-form-model-item> -->
        <g-title>正文</g-title>
        <a-form-model-item label="选项类型">
          <a-select v-model="formData.ruleType" placeholder="请选择选项类型">
            <a-select-option
              v-for="item in [
                { title: '统计', value: 'STATISTICS' },
                { title: '下拉选项', value: 'SELECT' }
              ]"
              :key="item.value"
              :value="item.value"
              >{{ item.title }}</a-select-option
            >
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="取值目标" prop="styleName">
          <a-tree-select
            v-model="formData.calcTargetId"
            placeholder="请选择取值目标"
            @change="treeSelectChange"
            ref="selectTree"
          >
            <a-tree-select-node v-for="item in treeData" :value="item.value" :key="item.key" :title="item.title">
              <!--               <div slot="title" style="display:flex;justify-content: space-between;">
                <span>{{ item.title }}</span>
                                <div>
                  <a-icon type="eye" @click.stop.prevent="showAllEye(item)" />
                </div>
              </div> -->
              <template v-for="data in item.children">
                <a-tree-select-node :value="data.value" :key="data.key" :title="data.title">
                  <!--                   <div slot="title" style="display:flex;justify-content: space-between;">
                    <span>{{ data.title }}</span>
                                         <div>
                      <a-icon
                        type="eye-invisible"
                        @click.stop.prevent="
                          e => {
                            showEye(e, data, item)
                          }
                        "
                      />
                    </div>
                  </div> -->
                  <a-tree-select-node v-for="i in data.children" :value="i.id" :key="i.id" :title="i.title">
                    <!-- <div slot="title" style="display:flex;justify-content: space-between;">
                      <span>{{ i.title }}</span>
                    </div> -->
                  </a-tree-select-node>
                </a-tree-select-node>
              </template>
            </a-tree-select-node>
          </a-tree-select>
        </a-form-model-item>
        <a-form-model-item v-if="formData.ruleType != 'SELECT'" label="取值范围">
          <edit-range-select :formData="formData.calcRange" :rangeKey="'type'" :valueKey="'value'" />
        </a-form-model-item>
        <a-form-model-item v-if="formData.ruleType != 'SELECT'" label="计算公式">
          <a-input v-model="formData.formula" :placeholder="'请输入计算公式'" />
        </a-form-model-item>
        <a-form-model-item v-if="formData.ruleType != 'SELECT'" label="计算逻辑">
          <a-select v-model="formData.calcLogic" placeholder="请选择计算逻辑">
            <a-select-option
              v-for="item in [
                { value: 'AVERAGE_VALUABLE', label: '平均值(除以单独取值话数)' },
                { value: 'AVERAGE_FIXED', label: '平均值(除以固定话数)' },
                { value: 'FIXED', label: '固定值' },
                { value: 'SUM', label: '总和' }
              ]"
              :key="item.value"
              :value="item.value"
              >{{ item.label }}</a-select-option
            >
          </a-select>
        </a-form-model-item>
        <a-form-model-item v-if="formData.calcLogic == 'AVERAGE_FIXED'" label="固定话数">
          <a-input-number v-model="formData.calcCount" :placeholder="'请输入固定话数'" />
        </a-form-model-item>
      </a-form-model>
    </div>
  </drawer-layout>
</template>

<script>
import { DrawerMixins } from '@/mixins/DrawerMixins'
import Vue from 'vue'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import { batchStyleInsertHeader } from '@/utils/util'
import EditItemSelect from '@/components/edit/edit-item-select.vue'
import EditRangeSelect from '@/components/edit/edit-range-select.vue'
const languageConfig = [
  {
    value: '中文简体',
    id: '中文简体'
  },
  {
    value: '中文繁体',
    id: '中文繁体'
  },
  {
    value: '日语',
    id: '日语'
  },
  {
    value: '韩语',
    id: '韩语'
  },
  {
    value: '英语',
    id: '英语'
  }
]
const fontUsages = [
  {
    value: '中文简体',
    id: '中文简体'
  }
]

const defaultFormData = {
  displayName: '',
  englishName: '',
  serialName: '',
  styleName: '',
  fontUsages: '',
  languages: [],
  departments: []
}
const defaultSelectedDeparts = []
const defaultSelectedPositions = []

export default {
  mixins: [DrawerMixins],
  components: {
    EditItemSelect,
    EditRangeSelect
  },
  props: {
    treeData: {
      type: Array,
      default: () => {
        ;[]
      }
    }
  },
  data() {
    return {
      headers: {
        'X-Access-Token': Vue.ls.get(ACCESS_TOKEN)
      },
      selectedDeparts: [],
      oldFormData: {},
      languageConfig,
      selectedPositions: [],
      rules: {
        // displayName: [{ required: true, message: '请输入字体中文名称', trigger: 'blur' }]
      },
      url: {
        edit: '/factor',
        add: '/factor'
      },
      labelWidth: '120',
      layout: {
        labelCol: {
          style: 'width: 120px',
          span: 8
        },
        wrapperCol: {
          span: 16
        }
      }
    }
  },
  created() {},
  methods: {
    getCalcTarget(value) {
      let text = ''
      this.treeData.forEach(item => {
        item.children.forEach(i => {
          if (i.children) {
            i.children.forEach(data => {
              if (data.id == value) {
                text = data.title
              }
            })
          }
        })
      })
      return text
    },
    getCalcRange(data) {
      let text = ''
      let unit = ''
      if (data.unit) {
        switch (data.unit) {
          case 'EACH':
            unit = '个'
            break
          case 'CHAPTER':
            unit = '话'
            break
          case 'VOLUME':
            unit = '部'
            break
          case 'PAGE':
            unit = '页'
            break
          case 'WORD':
            unit = '字'
            break
          case 'THOUSAND':
            unit = '千字'
            break
          case 'GRID':
            unit = '格'
            break
          case 'PERCENT':
            unit = '%'
            break
        }
        if (data.type) {
          switch (data.type) {
            case 'GT':
              data.value != null && (text = '>' + data.value + unit)
              break
            case 'LT':
              data.value != null && (text = '<' + data.value + unit)
              break
            case 'EQ':
              data.value != null && (text = '=' + data.value + unit)
              break
            case 'BETWEEN':
              data.start != null && (text = data.start + '~' + data.end + unit)
              break
          }
          return text
        }
        return text
      }
      return text
    },
    showEye(e, data, item) {
      console.log(e)
      data.isShow = false
      item.showList.push(data.key)
    },
    showAllEye(item) {
      item.children.forEach(data => {
        item.showList.forEach(key => {
          if (data.key == key) {
            data.isShow = !data.isShow
          }
        })
      })
    },
    treeSelectChange(value) {
      this.treeData.forEach(item => {
        item.children.forEach(i => {
          if (i.children) {
            i.children.forEach(data => {
              if (data.id == value) {
                this.formData.calcTargetType = i.value
              }
            })
          }
        })
      })
      /* this.formData.calcTargetType=value; */
    },
    closeSelect() {
      /* console.log('closeSelect') */
      this.$refs.editItemSelectName?.closeOpen()
      this.$refs.editItemSelectDescription?.closeOpen()
    },

    initForm() {
      this.formData = {
        ...defaultFormData
      }
      this.selectedDeparts = [...defaultSelectedDeparts]
      this.selectedPositions = [...defaultSelectedPositions]
    },

    show() {
      console.log('显示...')
      this.getDepartList()
      this.getPosition()
      if (this.isEdit) {
        this.formData = this.curRow
        // console.log(this.formData, this.curRow,"this.formData = this.curRow")
        const { departments, positions } = this.formData
        departments && (this.selectedDeparts = departments.map(item => item.departId))
        positions && (this.selectedPositions = positions.map(item => item.positionId))
      }
      if (!this.formData.calcRange) {
        this.formData = Object.assign({}, this.formData, {
          calcRange: {
            range: 'GT',
            unit: 'CHAPTER',
            value: '',
            start: null,
            end: null,
            id: null
          }
        })
      }
      this.oldFormData = { ...this.formData }
    },

    startEdit() {
      if (this.isEdit) {
        this.formData = this.curRow
        const { departments, positions } = this.formData
        departments && (this.selectedDeparts = departments.map(item => item.departId))
        if (positions) {
          this.selectedPositions = positions.map(item => item.positionId)
        }
      } else {
      }
      if (!this.formData.fontUsages) {
        this.formData.fontUsages = []
      }
      if (!this.formData.calcRange) {
        this.formData = Object.assign({}, this.formData, {
          calcRange: {
            range: 'GT',
            unit: 'CHAPTER',
            value: '',
            start: null,
            end: null,
            id: null
          }
        })
      }
      this.oldFormData = { ...this.formData }
    },

    setFormData() {
      console.log(this.formData)
      this.formData.own = 'DIFFICULT'
    },
    handleUploadChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList)
      }
      if (info.file.status === 'done') {
        if (info.file.response.success) {
          if (!this.formData.id) {
            this.formData = {
              ...this.formData,
              ...info.file.response.data,
              languages: [],
              fontUsages: [],
              departments: this.selectedDeparts
            }
          } else {
            this.formData.englishName = info.file.response.data.englishName
            this.formData.fontFamily = info.file.response.data.fontFamily
            this.formData.postScriptName = info.file.response.data.postScriptName
            this.formData.fontFilePath = info.file.response.data.fontFilePath
            this.formData.cssFilePath = info.file.response.data.cssFilePath
          }
          batchStyleInsertHeader([this.formData])
          this.$message.success(`${info.file.name} file uploaded successfully`)
        } else {
          this.$message.error(`处理字体失败,${info.file.response.message}`)
        }
      } else if (info.file.status === 'error') {
        this.$message.error(`${info.file.name} file upload failed.`)
      }
    }
  },
  computed: {}
}
</script>

<style lang="stylus">
.upload-btn {
  margin-bottom: 20px;
}
.border-bottom {
  border-bottom:none;
}
</style>
